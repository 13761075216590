import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../../../components/loader/Loader";
import apiRequests from "../../../services/config";

import "./Menu.css";

export default function Menu() {
  const [groups, setGroups] = useState([]);
  const [groupID, setGroupID] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [groupType, setGroupType] = useState(0);
  const [isLoader, setIsLoader] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isvalid, setIsvalid] = useState(false);

  const showGroups = async () => {
    setIsLoader(true);
    await apiRequests("/groups/list").then((result) => {
      setGroups(result.data.data);

      setIsLoader(false);
    });
  };

  const createMenu = async (event) => {
    event.preventDefault();

    setIsLoader(true);
    let createGroup = {
      name: groupName,
      type: groupType,
    };

    await apiRequests.post(`/groups/create`, createGroup).then((result) => {
      showGroups();
      setGroupName("");
      setGroupType(-1);

      setIsLoader(false);
      swal({
        title: "Successfully.",
        icon: "success",
        buttons: "Ok",
      });
    });
  };

  const deleteGroup = async (groupID) => {
    swal({
      title: "Are you sure about deleting?",
      icon: "warning",
      buttons: ["Np", "Yes"],
    }).then(async (result) => {
      if (result) {
        setIsLoader(true);
        await apiRequests(`/groups/delete/${groupID}`).then((result) =>
          setIsLoader(false)
        );
        swal({
          title: "Successfully.",
          icon: "success",
          buttons: "Ok",
        });

        showGroups();
      }
    });
  };

  const showGroup = async (groupID) => {
    setIsUpdate(true);
    setGroupID(groupID);

    await apiRequests(`/groups/show/${groupID}`).then((result) => {
      setGroupName(result.data.data.name);
      setGroupType(result.data.data.type);
    });
  };

  const updateGroupHandller = async (event) => {
    event.preventDefault();
    setIsLoader(true);

    let updateGroup = {
      name: groupName,
      type: groupType,
    };

    await apiRequests
      .post(`/groups/update/${groupID}`, updateGroup)
      .then((result) => {
        setIsLoader(false);
        swal({
          title: "Successfully.",
          icon: "success",
          buttons: "Ok",
        });
        showGroups();
        setGroupID(0);
        setGroupName("");
        setGroupType(-1);
        setIsUpdate(false);
      });
  };

  const cancelChange = () => {
    setGroupName("");
    setGroupType(0);
  };

  useEffect(() => {
    showGroups();
  }, []);

  useEffect(() => {
    if (groupName.length && groupType > 0) {
      if (!isUpdate) {
        setIsvalid(true);
      }
    } else {
      setIsvalid(false);
      setIsUpdate(false);
    }
  }, [groupName, groupType]);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <div>
      <h1 className="fw-bold">Group</h1>
      <div className="row justify-content-evenly">
        <div className="shadow rounded-4 my-4 py-3 ">
          <div>
            <div className="row">
              <div className="mb-4">
                <h4 className="my-3 feature-title">Title</h4>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom01"
                  value={groupName}
                  onChange={(event) => setGroupName(event.target.value)}
                  required=""
                />
              </div>
              <div className="form-group">
                <label for="exampleSelect" className="">
                  Select the main group
                </label>
                <select
                  name="select"
                  id="exampleSelect"
                  className="form-control"
                  value={groupType}
                  onChange={(event) => setGroupType(event.target.value)}
                >
                  <option value={-1}>Choose ...</option>
                  <option value={1}>Detectors</option>
                  <option value={2}>Accessories</option>
                </select>
              </div>
            </div>
            {isvalid ? (
              <button
                className="btn btn-primary btn-color my-4 me-3"
                type="submit"
                onClick={createMenu}
              >
                Create
              </button>
            ) : (
              <button
                disabled
                className="btn btn-primary btn-color my-4 me-3"
                type="submit"
                onClick={createMenu}
              >
                Create
              </button>
            )}
            {isUpdate ? (
              <button
                className="btn btn-primary btn-color my-4"
                type="submit"
                onClick={updateGroupHandller}
              >
                Update
              </button>
            ) : (
              <button
                disabled
                className="btn btn-primary btn-color my-4"
                type="submit"
              >
                Update
              </button>
            )}
            <button
              className="btn btn-danger btn-color my-4 ms-3"
              onClick={cancelChange}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <table className="table table-dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th className="w-50px ">Action</th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group, index) => (
              <tr key={group.id}>
                <th scope="row">{index + 1}</th>
                <td>{group.name}</td>
                <td className="text-center">
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteGroup(group.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                  </button>

                  <button
                    class="btn btn-success"
                    onClick={() => showGroup(group.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
