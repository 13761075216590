import React, { useEffect, useState } from "react";
import Loader from "../../../components/loader/Loader";
import apiRequests from "../../../services/config";
import Editor from "../../../components/adminPanel/form/Editor";

import "./Settings.css";

function Settings() {
  const [brand, setBrand] = useState("");
  const [logo, setLogo] = useState("");
  const [firstSlider, setFirstSlider] = useState({});
  const [secondSlider, setSecondSlider] = useState({});
  const [thridSlider, setThridSlider] = useState({});
  const [appStore, setAppStore] = useState("");
  const [playStore, setPlayStore] = useState("");
  const [apkLink, setApkLink] = useState("");
  const [appTitle, setAppTitle] = useState("");
  const [shortDescriptionApp, setShortDescriptionApp] = useState("");
  const [descriptionApp, setDescriptionApp] = useState("");
  const [featuresApp, setFeaturesApp] = useState("");
  const [whereToBuy, setWhereToBuy] = useState("");
  const [telegramAccont, setTelegramAccont] = useState("");
  const [instagramAccont, setInstagramAccont] = useState("");
  const [whatsappAccont, setWhatsappAccont] = useState("");
  const [appSlider, setAppSlider] = useState([]);
  const [contactUs, setContactUs] = useState("");

  const [baseUrl, setBaseUrl] = useState("");

  const [isLoader, setIsLoader] = useState(true);

  const getSettings = async () => {
    setIsLoader(true);
    await apiRequests("/get-setting").then((res) => {
      const sliderData = JSON.parse(res.data.data.sliders);
      const appSliders = JSON.parse(res.data.data.app_sliders);
      document.title = res.data.data.brand;

      setBrand(res.data.data.brand);
      setLogo(res.data.data.logo);
      setAppStore(res.data.data.appstore_link);
      setPlayStore(res.data.data.googleplay_link);
      setApkLink(res.data.data.apk_path);
      setAppTitle(res.data.data.app_title);
      setDescriptionApp(res.data.data.app_description);
      setFeaturesApp(res.data.data.app_features);
      setWhereToBuy(res.data.data.where_to_buy);
      setTelegramAccont(res.data.data.telegram);
      setInstagramAccont(res.data.data.instagram);
      setWhatsappAccont(res.data.data.whatsapp);
      setContactUs(res.data.data.contact_us);
      setAppSlider(appSliders);

      setFirstSlider(sliderData[0]);
      setSecondSlider(sliderData[1]);
      setThridSlider(sliderData[2]);
      setIsLoader(false);
    });
  };

  const uploader = async (event) => {
    setIsLoader(true);
    let fileID = null;
    let fileUrl = "";

    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer 15|2TuyZks9sy04RtnveX53BQpf5PFtrYxEqaGO7hfef18911c4"
    );

    let formdata = new FormData();
    formdata.append("image", event.target.files[0]);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch(
      "https://meta-detector.com/v1/api/file/upload-file",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        fileID = result.data.id;
        fileUrl = result.data.url;

        setIsLoader(false);
      })
      .catch((error) => console.log("error", error));

    switch (event.target.name) {
      case "first-slide":
        setFirstSlider((preveState) => ({
          ...preveState,
          image1: fileUrl,
        }));
        break;
      case "second-slide":
        setSecondSlider((preveState) => ({
          ...preveState,
          image1: fileUrl,
        }));
        break;
      case "there-slide":
        setThridSlider((preveState) => ({
          ...preveState,
          image1: fileUrl,
        }));
        break;
      case "app-slider":
        setAppSlider((preveState) => [
          ...preveState,
          { id: fileID, url: fileUrl },
        ]);
        break;
      case "apk-app":
        setApkLink(fileUrl);
        break;
      case "imag-logo":
        setLogo(fileUrl);
        break;
    }
  };

  const submitSettingDetails = async () => {
    let settingHome = {
      brand: brand,
      logo: logo,
      sliders: [
        {
          link: firstSlider.link,
          title: firstSlider.title,
          description: firstSlider.description,
          image1: firstSlider.image1,
        },
        {
          link: secondSlider.link,
          title: secondSlider.title,
          description: secondSlider.description,
          image1: secondSlider.image1,
        },
        {
          link: thridSlider.link,
          title: thridSlider.title,
          description: thridSlider.description,
          image1: thridSlider.image1,
        },
      ],
      appstore_link: appStore,
      googleplay_link: playStore,
      apk_path: apkLink,
      instagram: instagramAccont,
      telegram: telegramAccont,
      whatsapp: whatsappAccont,
      where_to_buy: whereToBuy,
      contact_us: contactUs,
      app_description: descriptionApp,
      app_features: featuresApp,
      app_title: appTitle,
      app_sliders: appSlider,
    };

    await apiRequests
      .post("/set-setting", settingHome)
      .then((res) => getSettings());
  };

  const deleteAppImagePerive = (imgId) => {
    let newAppSliders = appSlider.filter((img) => img.id !== imgId);

    setAppSlider(newAppSliders);
  };

  useEffect(() => {
    setBaseUrl(window.location.origin);

    getSettings();
  }, []);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <div>
      <h1 className="fw-bold">Settings</h1>
      {/* <Loader loaderStyle={!isLoader ? "flex" : "none"} /> */}
      <div className="row shadow rounded-4 my-4 py-3 row-gap-3">
        <h3 className="fw-bold">Logo and brand</h3>
        <div className="col-md-6 logo-wrapper">
          <h4 className="my-3 feature-title">
            <span className="feature-subtitle">
              ( The logo size must be <span>90*120 px</span> )
            </span>
            Logo
          </h4>
          <div className="imags-uploader">
            <label for="file1" className="custum-file-upload">
              <div className="icon">
                <svg
                  viewBox="0 0 24 24"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fillRule="evenodd"
                      clip-rule="evenodd"
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      fill=""
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <input
                id="file1"
                type="file"
                name="imag-logo"
                onChange={uploader}
              />
            </label>

            <div className="image-preview">
              <img src={`${baseUrl}${logo}`} alt="" />
              <i
                classNam={`${logo && "bi bi-trash"}`}
                onClick={() => setLogo("")}
              >
                {logo && "X"}
              </i>
            </div>
          </div>
        </div>

        <div className="border-top my-3">
          <div className="row">
            <div>
              <h4 className="my-3 feature-title">Site title</h4>
              <input
                name="serial"
                type="text"
                className="form-control"
                id="brand"
                value={brand}
                onChange={(event) => setBrand(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Uploder Slider */}
      <div className="row align-items-start shadow rounded-4 my-5 py-3 setting-uploder">
        <h3 className="fw-bold">Sliders</h3>

        <div className="col-12 my-3">
          <h4 className="my-3 feature-title">
            <span className="feature-subtitle">
              ( The image size must be <span>900*1600 px</span> )
            </span>
            First image of the homepage
          </h4>
          <div className="imags-uploader">
            <label for="file2" className="custum-file-upload">
              <div className="icon">
                <svg
                  viewBox="0 0 24 24"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fillRule="evenodd"
                      clip-rule="evenodd"
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      fill=""
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <input
                id="file2"
                type="file"
                name="first-slide"
                onChange={uploader}
              />
            </label>

            <div className="image-preview">
              <img src={`${baseUrl}${firstSlider?.image1}`} alt="" />
              <i
                classNam={`${firstSlider?.image1 && "bi bi-trash"}`}
                onClick={() =>
                  setFirstSlider((prevState) => ({
                    ...prevState,
                    image1: null,
                  }))
                }
              >
                {firstSlider?.image1 && "X"}
              </i>
            </div>

            <div className="form-group w-75">
              <input
                className="form-control"
                type="text"
                placeholder="link"
                value={firstSlider?.link}
                onChange={(event) =>
                  setFirstSlider((prevState) => ({
                    ...prevState,
                    link: event.target.value,
                  }))
                }
              />
            </div>

            <div className="d-flex flex-wrap align-items-start gap-3 w-100 image-information">
              <input
                className="form-control"
                type="text"
                name="slider-first"
                placeholder="Image title"
                value={firstSlider?.title}
                onChange={(event) =>
                  setFirstSlider((prevState) => ({
                    ...prevState,
                    title: event.target.value,
                  }))
                }
              />
            </div>
            <div className="col-12">
              <h4 className="my-3 feature-title">Description</h4>

              <textarea
                value={firstSlider?.description}
                onChange={(event) =>
                  setFirstSlider((prevState) => ({
                    ...prevState,
                    description: event.target.value,
                  }))
                }
              ></textarea>
            </div>
          </div>
        </div>

        <div className="col-12 border-top my-3">
          <h4 className="my-3 m-0 feature-title">
            <span className="feature-subtitle">
              ( The image size must be <span>640*360 px</span> )
            </span>
            Second image of the home page
          </h4>
          <div className="imags-uploader">
            <label for="file3" className="custum-file-upload">
              <div className="icon">
                <svg
                  viewBox="0 0 24 24"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fillRule="evenodd"
                      clip-rule="evenodd"
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      fill=""
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <input
                id="file3"
                type="file"
                name="second-slide"
                onChange={uploader}
              />
            </label>

            <div className="image-preview">
              <img src={`${baseUrl}${secondSlider?.image1}`} alt="" />
              <i
                classNam={`${secondSlider?.image1 && "bi bi-trash"}`}
                onClick={() =>
                  setSecondSlider((prevState) => ({
                    ...prevState,
                    image1: null,
                  }))
                }
              >
                {secondSlider?.image1 && "X"}
              </i>
            </div>

            <div className="form-group w-75">
              <input
                className="form-control"
                type="text"
                placeholder="link"
                value={secondSlider?.link}
                onChange={(event) =>
                  setSecondSlider((prevState) => ({
                    ...prevState,
                    link: event.target.value,
                  }))
                }
              />
            </div>

            <div class="d-flex flex-wrap align-items-start gap-3 w-100 image-information">
              <input
                className="form-control"
                type="text"
                placeholder="Image title"
                value={secondSlider?.title}
                onChange={(event) =>
                  setSecondSlider((prevState) => ({
                    ...prevState,
                    title: event.target.value,
                  }))
                }
              />
              <Editor
                value={secondSlider?.description}
                setValue={(event) =>
                  setSecondSlider((prevState) => ({
                    ...prevState,
                    description: event,
                  }))
                }
              />
            </div>
          </div>
        </div>

        <div className="col-12 border-top my-3">
          <h4 className="my-3 feature-title">
            <span className="feature-subtitle">
              ( The image size must be <span>640*360 px</span> )
            </span>
            Third image of the home page
          </h4>
          <div className="imags-uploader">
            <label for="file4" className="custum-file-upload">
              <div className="icon">
                <svg
                  viewBox="0 0 24 24"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fillRule="evenodd"
                      clip-rule="evenodd"
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      fill=""
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <input
                id="file4"
                type="file"
                name="there-slide"
                onChange={uploader}
              />
            </label>

            <div className="image-preview">
              <img src={`${baseUrl}${thridSlider?.image1}`} alt="" />
              <i
                classNam={`${thridSlider?.image1 && "bi bi-trash"}`}
                onClick={() =>
                  setThridSlider((prevState) => ({
                    ...prevState,
                    image1: null,
                  }))
                }
              >
                {thridSlider?.image1 && "X"}
              </i>
            </div>

            <div className="form-group w-75">
              <input
                className="form-control"
                type="text"
                placeholder="link"
                value={thridSlider?.link}
                onChange={(event) =>
                  setThridSlider((prevState) => ({
                    ...prevState,
                    link: event.target.value,
                  }))
                }
              />
            </div>

            <div class="d-flex flex-wrap align-items-start gap-3 w-100 image-information">
              <input
                className="form-control"
                type="text"
                placeholder="Image title"
                value={thridSlider?.title}
                onChange={(event) =>
                  setThridSlider((prevState) => ({
                    ...prevState,
                    title: event.target.value,
                  }))
                }
              />
              <Editor
                value={thridSlider?.description}
                setValue={(event) =>
                  setThridSlider((prevState) => ({
                    ...prevState,
                    description: event,
                  }))
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* Uplodar App & description */}
      <div className="row shadow rounded-4 my-5 py-3 row-gap-2">
        <h3 className="fw-bold">Mobile app</h3>

        <div className="col-md-6">
          <h4 className="my-3 feature-title">Android link</h4>
          <input
            name="serial"
            type="text"
            className="form-control"
            id="apkPlayLink"
            value={playStore}
            onChange={(event) => setPlayStore(event.target.value)}
          />
        </div>

        <div className="col-md-6">
          <h4 className="my-3 feature-title">iPhone link</h4>
          <input
            name="serial"
            type="text"
            className="form-control"
            id="iosLink"
            value={appStore}
            onChange={(event) => setAppStore(event.target.value)}
          />
        </div>

        <div className="col-md-6">
          <form>
            <div className="row">
              <div>
                <h4 className="my-3 feature-title">Title</h4>
                <input
                  name="serial"
                  type="text"
                  className="form-control"
                  id="appTitle"
                  value={appTitle}
                  onChange={(event) => setAppTitle(event.target.value)}
                />
              </div>
            </div>
          </form>
        </div>

        <div className="col-md-6">
          <h4 className="my-3 feature-title">Direct link</h4>
          <label for="file5" className="custum-file-upload">
            <div className="icon">
              <svg
                viewBox="0 0 24 24"
                fill=""
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    fillRule="evenodd"
                    clip-rule="evenodd"
                    d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                    fill=""
                  ></path>{" "}
                </g>
              </svg>
            </div>
            <input id="file5" type="file" name="apk-app" onChange={uploader} />
          </label>
        </div>

        <div className="border-top my-3">
          <h4 className="mt-3 m-0 feature-title">
            <span className="feature-subtitle">
              ( The size of the app images must be <span>620*780 px</span> )
            </span>
            Pictures
          </h4>
          <div className="imags-uploader">
            <label for="file6" className="custum-file-upload">
              <div className="icon">
                <svg
                  viewBox="0 0 24 24"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fillRule="evenodd"
                      clip-rule="evenodd"
                      d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                      fill=""
                    ></path>{" "}
                  </g>
                </svg>
              </div>
              <input
                id="file6"
                type="file"
                name="app-slider"
                onChange={uploader}
              />
            </label>
            {appSlider?.map((image) => (
              <div key={image.id} className="image-preview">
                <img src={image.url} alt="" />
                <i onClick={() => deleteAppImagePerive(image.id)}>{`${"x"}`}</i>
              </div>
            ))}
          </div>
        </div>

        <div className="col-12 border-top my-3 py-2">
          <h4 className="pt-2 feature-title">Description</h4>
          <Editor value={descriptionApp} setValue={setDescriptionApp} />
        </div>

        <div className="col-12 border-top my-3 py-2">
          <h4 className="pt-2 feature-title">Features</h4>
          <Editor value={featuresApp} setValue={setFeaturesApp} />
        </div>
      </div>

      {/* Where to buy */}
      <div className="row shadow rounded-4 my-5 py-3">
        <div className="col-12">
          <h3 className="fw-bold">How to buy</h3>
          <Editor value={whereToBuy} setValue={setWhereToBuy} />
        </div>
      </div>

      {/* Contact (social media) */}
      <div className="row shadow rounded-4 my-5 py-3 row-gap-3">
        <h3 className="fw-bold">Social media</h3>

        <div className="col-md-6">
          <div className="row">
            <div>
              <h4 className="my-3 feature-title">Telegram link</h4>
              <input
                name="serial"
                type="text"
                className="form-control"
                id="telegramLink"
                value={telegramAccont}
                onChange={(event) => setTelegramAccont(event.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="row">
            <div>
              <h4 className="my-3 feature-title">Instagram link</h4>
              <input
                name="serial"
                type="text"
                className="form-control"
                id="inestageramLink"
                value={instagramAccont}
                onChange={(event) => setInstagramAccont(event.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="row">
            <div>
              <h4 className="my-3 feature-title">WhatsApp link</h4>
              <input
                name="serial"
                type="text"
                className="form-control"
                id="whatsupLink"
                value={whatsappAccont}
                onChange={(event) => setWhatsappAccont(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row shadow rounded-4 my-5 py-3 row-gap-3">
        <div className="col-12">
          <h3 className="fw-bold">Contact us</h3>
          <Editor value={contactUs} setValue={setContactUs} />
        </div>

        <button
          className="btn btn-primary btn-color my-4 ms-3 w-25 align-align-self-start"
          type="submit"
          onClick={submitSettingDetails}
        >
          Send
        </button>
      </div>
    </div>
  );
}

export default Settings;
