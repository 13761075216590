import React, { useEffect, useState } from "react";
import apiRequests from "../../../services/config";

import "./Dashboard.css";
import Loader from "../../../components/loader/Loader";

export default function Dashboard() {
  const [products, setProducts] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  const getProducts = async () => {
    await apiRequests("https://meta-detector.com/v1/api/products").then(
      (result) => {
        setProducts(result.data.data);

        setIsLoader(false);
      }
    );
  };

  useEffect(() => {
    getProducts();
  }, []);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <div>
      <h1 className="mt-4 fw-bold">Dashboard</h1>
      <div className="row mt-3">
        <table className="table table-dark dashboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Pictuers</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={product.id}>
                <th scope="row">{index + 1}</th>
                <td>{product.title}</td>
                <td>
                  <div className="product-cover">
                    <img
                      src={`https://meta-detector.com/${product.image.url}`}
                      alt=""
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
