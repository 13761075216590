import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

import "./FAQ.css";
import Loader from "../../components/loader/Loader";
import { Helmet } from "react-helmet";

export default function FAQ() {
  const { faq_name } = useParams();
  // const [faqTitle, setFaqTitle] = useState("");
  const [faqsData, setFaqsData] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    fetch("https://meta-detector.com/v1/api/faqs")
      .then((res) => res.json())
      .then((result) => {
        setFaqsData(result.data);

        setIsLoader(false);
      });
  }, []);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <>
      <Helmet>
        <title>{`Meta Detector | FAQ`}</title>
      </Helmet>
      <Header />
      <main id="content">
        <div className="container">
          <Breadcrumb
            links={
              faq_name
                ? [
                    { id: 1, title: "FAQ", link: "/faq" },
                    { id: 2, title: faq_name },
                  ]
                : [{ id: 1, title: "FAQ", link: "/faq" }]
            }
          />
          {!faq_name ? (
            <>
              <h1 className="faq-title">Thematic: </h1>
              <div className="thematique">
                {faqsData.map((faq) => (
                  <div key={faq.id} className="main-thematique">
                    <Link
                      to={`${faq.name}`}
                      className="btn"
                      title="DEUS II"
                      role="button"
                    >
                      {faq.name}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              {/* <h1 className="faq-title">test</h1> */}
              {faqsData.map(
                (item) =>
                  item.name === faq_name &&
                  item.faqs.map((faq) => (
                    <div key={faq.id}>
                      <div className="acc-faq">
                        <h3
                          className=""
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseExample${faq.id}`}
                        >
                          <span>{faq.question}</span>
                        </h3>
                        <div
                          className="content-faq question86 collapse"
                          id={`collapseExample${faq.id}`}
                        >
                          <div className="content-inner-faq richtext page">
                            <div className="row">
                              <div className="col-12">
                                <div className="pJustifie pStyleModule2">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: faq.answer,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
}
