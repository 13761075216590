import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Editor({ value, setValue }) {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData();

        setValue(data);
      }}
      // config={{
      //   toolbar: [
      //     "heading",

      //     "bold",
      //     "italic",
      //     "link",
      //     "bulletedList",
      //     "numberedList",

      //     "alignment:left",

      //     "undo",
      //     "redo",
      //   ],
      // }}
    />
  );
}
