import React, { useEffect, useState } from "react";
import Header from "./../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loader/Loader";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import apiRequests from "../../services/config";
import { Helmet } from "react-helmet";

function WhereToBuy() {
  const [isLoader, setIsLoader] = useState(true);
  const [buyDescription, setBuyDescription] = useState("");

  useEffect(() => {
    apiRequests("/get-setting").then((res) => {
      setBuyDescription(res.data.data.where_to_buy);
      setIsLoader(false);
    });
  }, []);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }
  return (
    <>
      <Helmet>
        <title>{`Meta Detector | Where To Buy`}</title>
      </Helmet>
      <Header />
      <main id="content" style={{ paddingBottom: "0" }}>
        <div className="container-fluid">
          <Breadcrumb links={[{ id: 3, title: "Where To Buy?" }]} />
          <div
            className="wrapper"
            dangerouslySetInnerHTML={{ __html: buyDescription }}
          ></div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default WhereToBuy;
