import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../../../components/loader/Loader";
import apiRequests from "../../../services/config";

function Serials() {
  const [serials, setSerials] = useState([]);
  const [serialsFilter, setSerialsFilter] = useState([]);
  const [search, setSearch] = useState({});
  const [isLoader, setIsLoader] = useState(true);

  const getSerialsList = async () => {
    await apiRequests("/serials/list").then((result) => {
      setSerials(result.data.data);

      console.log(serials);

      setIsLoader(false);
    });
  };

  const blockAndUnblockSerials = async (serialID) => {
    console.log("1");
    swal({
      title: "Are you sure about blocking or unblocking?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(async (result) => {
      if (result) {
        setIsLoader(true);
        await apiRequests(`/serials/block/${serialID}`).then((result) => {
          console.log("2");
          swal({
            title: "Successfully.",
            icon: "success",
            buttons: "ok",
          });
          getSerialsList();
          setIsLoader(false);
        });
      }
    });
  };

  const deleteSerial = async (serialID) => {
    swal({
      title: "Are you sure about deleting?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(async (result) => {
      if (result) {
        setIsLoader(true);
        await apiRequests(`/serials/delete/${serialID}`).then((result) => {
          swal({
            title: "Successfully deleted.",
            icon: "success",
            buttons: "ok",
          });
          getSerialsList();
          setIsLoader(false);
        });
      }
    });
  };

  const searchSerialImei = (event) => {
    event.preventDefault();

    let serialsFilter = [];
    if (search.name === "serial") {
      serialsFilter = serials.filter(
        (serial) => serial.serial === search.value
      );
    } else if (search.name === "imei") {
      serialsFilter = serials.filter((serial) => serial.imei === search.value);
    }

    setSerialsFilter(serialsFilter);
    setSerials(serials);
  };

  useEffect(() => {
    getSerialsList();
  }, []);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <div>
      <h1 className="fw-bold">Serials</h1>
      <div className="row justify-content-evenly">
        <div className="shadow rounded-4 my-4 py-3">
          <div>
            <form className="p-2">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <h4 className="my-3 feature-title">Serial</h4>
                  <input
                    name="serial"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    onChange={(event) =>
                      setSearch({
                        name: event.target.name,
                        value: event.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-md-6 mb-4">
                  <h4 className="my-3 feature-title">imei</h4>
                  <input
                    name="imei"
                    type="text"
                    className="form-control"
                    id="validationCustom02"
                    onChange={(event) =>
                      setSearch({
                        name: event.target.name,
                        value: event.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <button
                className="btn btn-primary btn-color my-4 me-3"
                type="submit"
                onClick={searchSerialImei}
              >
                Search
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <table className="table table-dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Serials</th>
              <th>imei</th>
              <th className="w-50px ">Action</th>
            </tr>
          </thead>
          <tbody>
            {serialsFilter.length
              ? serialsFilter.map((serial, index) => (
                  <tr key={serial.id}>
                    <th scope="row">{index + 1}</th>
                    <td>{serial.serial}</td>
                    <td>{serial.imei}</td>
                    <td className="text-center">
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteSerial(serial.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))
              : serials.map((serial, index) => (
                  <tr key={serial.id}>
                    <th scope="row">{index + 1}</th>
                    <td
                      className={`${
                        !serial.block ? "text-info" : "text-warning"
                      }`}
                    >
                      {serial.serial}
                    </td>
                    <td
                      className={`${
                        !serial.block ? "text-info" : "text-warning"
                      }`}
                    >
                      {serial.imei}
                    </td>
                    <td className="text-center">
                      <button
                        className="btn btn-danger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete"
                        onClick={() => deleteSerial(serial.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                        </svg>
                      </button>
                      {!serial.block ? (
                        <button
                          className="btn btn-warning"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Lock"
                          onClick={() => blockAndUnblockSerials(serial.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-lock"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1" />
                          </svg>
                        </button>
                      ) : (
                        <button
                          className="btn btn-info"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Unlock"
                          onClick={() => blockAndUnblockSerials(serial.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-unlock"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2M3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z" />
                          </svg>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Serials;
