import React, { useEffect, useState } from "react";
import Topbar from "../topbar/Topbar";
import Navbar from "../navbar/Navbar";

import "./Header.css";
import apiRequests from "../../services/config";
import { Link } from "react-router-dom";

const Header = () => {
  const [logo, setLogo] = useState("");

  const getSettingsPage = async () => {
    await apiRequests("/get-setting").then((res) => {
      setLogo(res.data.data.logo);
    });
  };

  useEffect(() => {
    getSettingsPage();
  }, []);
  return (
    <header>
      <Topbar />

      <div className="header-bas">
        <div className="container-fluid">
          <Link
            to={window.location.origin}
            className="logo"
            style={{ opacity: "1" }}
          >
            <img src={`https://meta-detector.com${logo}`} alt="" />
          </Link>

          <Navbar />
        </div>
      </div>
    </header>
  );
};

export default Header;
