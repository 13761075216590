import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import apiRequests from "../../../services/config";
import Loader from "../../../components/loader/Loader";

export default function Faq() {
  const [faqLists, setFaqLists] = useState([]);
  const [faqID, setFaqID] = useState(0);
  const [faqTitle, setFaqTitle] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const faqGroupLists = async () => {
    await apiRequests("/faq-groups/list").then((result) => {
      setFaqLists(result.data.data);

      setIsLoader(false);
    });
  };

  const createFaq = async (event) => {
    event.preventDefault();

    setIsLoader(true);

    if (faqTitle.trim().length) {
      await apiRequests
        .post("/faq-groups/create", { name: faqTitle })
        .then((result) => {
          swal({
            title: "گروه FAQ با موفقیت ایجاد شد.",
            icon: "success",
            buttons: "حله",
          });

          setFaqTitle("");
          faqGroupLists();
          setIsLoader(false);
        });
    }
  };

  const deleteFaqGroup = async (faqID) => {
    swal({
      title: "Are you sure about deleting?",
      icon: "warning",
      buttons: ["No", "Yes"],
    }).then(async (result) => {
      if (result) {
        await apiRequests(`/faq-groups/delete/${faqID}`);
        swal({
          title: "Successfully.",
          icon: "success",
          buttons: "Ok",
        });
        faqGroupLists();
      }
    });
  };

  const editFaqGroup = (faq) => {
    setIsUpdate(true);
    setFaqID(faq.id);
    setFaqTitle(faq.name);
  };

  const updateFaqGroup = async (event) => {
    event.preventDefault();

    setIsLoader(true);

    if (faqID) {
      await apiRequests
        .post(`/faq-groups/update/${faqID}`, { name: faqTitle })
        .then((result) => {
          swal({
            title: "Successfully.",
            icon: "success",
            buttons: "Ok",
          });
          faqGroupLists();
          setFaqTitle("");
          setIsLoader(false);
          setIsUpdate(false);
        });
    }
  };

  const cancelChange = () => {
    setFaqTitle("");
  };

  useEffect(() => {
    faqGroupLists();
  }, []);

  useEffect(() => {
    if (faqTitle.length) {
      if (!isUpdate) {
        setIsValid(true);
      }
    } else {
      setIsUpdate(false);
      setIsValid(false);
    }
  }, [faqTitle]);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <div>
      <h1 className="fw-bold">FAQ Group</h1>
      <div className="row justify-content-evenly">
        <div className="shadow rounded-4 my-4 py-3">
          <div>
            <div className="row">
              <div className="mb-4">
                <h4 className="my-3 feature-title">Title</h4>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom01"
                  value={faqTitle}
                  onChange={(event) => setFaqTitle(event.target.value)}
                  required=""
                />
              </div>
            </div>
            {!isValid ? (
              <button
                disabled
                className="btn btn-primary btn-color me-3"
                type="submit"
                onClick={createFaq}
              >
                Create
              </button>
            ) : (
              <button
                className="btn btn-primary btn-color me-3"
                type="submit"
                onClick={createFaq}
              >
                Create
              </button>
            )}

            {isUpdate ? (
              <button
                className="btn btn-primary btn-color my-4"
                type="submit"
                onClick={updateFaqGroup}
              >
                Update
              </button>
            ) : (
              <button
                disabled
                className="btn btn-primary btn-color my-4"
                type="submit"
              >
                Update
              </button>
            )}

            <button
              className="btn btn-danger btn-color my-4 ms-3"
              onClick={cancelChange}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <table className="table table-dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th className="w-50px ">Action</th>
            </tr>
          </thead>
          <tbody>
            {faqLists.map((faq, index) => (
              <tr key={faq.id}>
                <th scope="row">{index + 1}</th>
                <td>{faq.name}</td>
                <td className="text-center">
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteFaqGroup(faq.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                  </button>

                  <button
                    class="btn btn-success"
                    onClick={() => editFaqGroup(faq)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
