import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./DownloadApp.css";
import apiRequests from "../../services/config";
import Loader from "../../components/loader/Loader";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
function DownloadApp() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [appData, setAppData] = useState({});

  const getSettingsPage = async () => {
    await apiRequests("/get-setting").then((res) => {
      const appSliders = JSON.parse(res.data.data.app_sliders);
      setAppData({
        appTitle: res.data.data.app_title,
        appDescription: res.data.data.app_description,
        appFeatures: res.data.data.app_features,
        googlePlay: res.data.data.googleplay_link,
        appStore: res.data.data.appstore_link,
        appLink: res.data.data.apk_path,
        appSiliders: appSliders,
      });

      setIsLoader(false);
    });
  };

  useEffect(() => {
    document.title = "Meta Detector | APP";
    getSettingsPage();
  }, []);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <>
      <Helmet>
        <title>{`Meta Detector | APP`}</title>
      </Helmet>

      <Header />

      <div className="download-main">
        <div>
          <div className="container-fluid">
            <Breadcrumb links={[{ id: 3, title: "GO TERRAIN Application" }]} />
            <div className="row align-items-lg-center margin-top-20">
              <div class="col-12 col-lg-6">
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {appData.appSiliders.map((img, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={`${window.location.origin}${img.url}`}
                        alt="APP"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <Swiper
                  onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="d-none d-lg-block mySwiper"
                >
                  {appData.appSiliders.map((img) => (
                    <SwiperSlide>
                      <img
                        src={`${window.location.origin}${img.url}`}
                        alt="app"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              <div className="col-12 col-lg-6">
                <h1 className="h1-product text-start active">
                  <span itemprop="name">{appData.appTitle}</span>
                </h1>
                <div className="description">
                  <div
                    className="desc text-start"
                    dangerouslySetInnerHTML={{
                      __html: appData.appDescription,
                    }}
                  ></div>
                </div>
                <br />
              </div>
            </div>
          </div>

          <div className="wrapper-bg ">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="app-logo__cover--direct">
                    {appData.appLink ? (
                      <Link to={appData.appLink}>
                        <img
                          src={`${window.location.origin}/img/staticPic/images.jfif`}
                          alt={appData.title}
                          className="img-fluid rounded-circle"
                        />
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="app-logo">
                    <div className="app-logo__cover">
                      {appData.appStore ? (
                        <Link to={appData.appStore}>
                          <img
                            src={`${window.location.origin}/img/staticPic/appS.png`}
                            alt="Download GO TERRAIN from App Store"
                            className="img-fluid"
                          />
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="app-logo__cover">
                      {appData.googlePlay ? (
                        <Link to={appData.googlePlay}>
                          <img
                            src={`${window.location.origin}/img/staticPic/playS.png`}
                            alt="Download GO TERRAIN from Google Play"
                            className="img-fluid"
                          />
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper wrapper-specs">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-xl-9 mt-5">
                      <div
                        className="wrapper"
                        dangerouslySetInnerHTML={{
                          __html: appData.appFeatures,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DownloadApp;
